export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.hook("vuetify:configuration", vuetify => {
    vuetify.vuetifyOptions = vuetify.vuetifyOptions || {};
    vuetify.moduleOptions = vuetify.moduleOptions || { styles: { configFile: "./styles/variables.scss" } };
    _.assign(vuetify.vuetifyOptions, {
      theme: {
        defaultTheme: "dark",
        themes: {
          light: {},
          dark: {
            dark: true,
            colors: {
              main: "#000204",
              leftarea: "#01152c",
              appbar: "#081828",
              background: "#000204",
              surface: "#000204", // for card color
              maintitle: "#ce9134",
              bglight: "1b4a72",
              rating: "#a77528",
              button: "#6daffd",
              buttonBackground: "#6daffd",
              iconColor: "#9e9eff",
              blueButton: "#6daffd",
              breadcrumbs: "#FFFFFF",
              orange: "#a34240",
              lightBlue: "#1e5685",
              darkBlue: "12304b",
              linkColor: "#8097ff",
            },
          },
        },
      },
    });
  });
});
